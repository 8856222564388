import { defineStore } from 'pinia';

export const useCarsStore = defineStore('carsStore', {
  state: () => ({
    list: [],
    counter: null,
    minPrice: 0,
    isPromocodeAccepted: false,
    loading: false,
    loadingButton: false,
    activeClassId: 0,
    isReadyPage: true,
    payload: {
      'from-id': null,
      'to-id': null,
      'to-date': null,
      'from-date': null,
      'from-time': null,
      'to-time': null,
      'promocode': null,
      'page': 1,
      'per_page': 24,
    }
  }),
  actions: {
    async get() {
      let params = new URLSearchParams(this.payload).toString();
      const { data: item } = await useFetch(`/api/v2/search_cars?${params}`);
      if (item.value) this.list.push(...item.value.vehicles);
      if (item.value) this.counter = item.value.vehiclesCount;
      if (item.value) this.minPrice = item.value.minPrice;
      if (item.value) this.isPromocodeAccepted = item.value.is_promocode_accepted;
      if (window.innerWidth < 761) {
        const el = document.getElementById('cars');
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
      this.loading = false;
      this.isReadyPage = false;
    },
    setClassId(id) {
      this.activeClassId = id
    },
    clearList() {
      this.list = [];
    },
  }
});